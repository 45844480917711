/* Ορισμός γραμματοσειράς */
@font-face {
  font-family: 'PFHighwayGothic';
  src: url('../../public/assets/PFHighwayGothicExtnd-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Εφαρμογή της νέας γραμματοσειράς στα links του μενού */
.menu-link {
  font-family: 'PFHighwayGothic', sans-serif;
}

/* Κύριο μενού */
.menu-container {
  position: fixed;
  top: 0;
  right: -100%;
  width: 20vw; /* Το μενού καλύπτει το 20% της οθόνης */
  height: 100vh; /* Κάλυψη όλου του ύψους της οθόνης */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
  transition: right 1s ease-in-out;
  z-index: 1001;
}

.menu-open {
  right: 0;
}

/* Κουμπί κλεισίματος (X) */
.menu-close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Links μενού */
.menu-links-container {
  position: relative;
  top: 20%; /* Προσαρμόσαμε το top για να υπάρχει αρκετός χώρος για τα social icons */
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.menu-link {
  font-size: 2rem;
  color: black;
  text-decoration: none;
  opacity: 0;
  transform: translateY(100vh);
  transition: opacity 0.8s ease, transform 1.5s ease;
  z-index: 10;
  text-align: left;
}

/* Εμφάνιση των links με slide-in εφέ */
.menu-open .slide-in-item {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.5s;
}

/* Αφαίρεση της γραμμής υπογράμμισης σε mobile */
@media (max-width: 768px) {
  .menu-link::before {
    display: none;
  }
}

/* Social media container */
.menu-socials-container {
  position: absolute;
  bottom: 5%; /* Τοποθέτηση στο κάτω μέρος του μενού */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Social media icons */
.menu-social-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Προσαρμογές για tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .menu-container {
    width: 40vw; /* Το μενού καλύπτει το 30% της οθόνης σε tablet */
    height: 100vh; /* Το μενού καλύπτει το πλήρες ύψος */
  }

  .menu-link {
    font-size: 1.8rem; /* Ελαφρώς μικρότερα links για tablet */
  }

  .menu-socials-container {
    bottom: 10%; /* Τα social icons παραμένουν στο κάτω μέρος */
  }

  .menu-social-icon {
    width: 28px; /* Προσαρμογή μεγέθους των social icons */
    height: 28px;
  }
}

/* Προσαρμογές για mobile */
@media (max-width: 768px) {
  .menu-link {
    font-size: 1.5rem;
  }

  .menu-container {
    width: 94vw; /* Το μενού καλύπτει όλη την οθόνη σε mobile */
    height: 100vh; /* Το μενού καλύπτει το πλήρες ύψος */
  }

  .menu-socials-container {
    bottom: 15%; /* Τα social icons παραμένουν στο κάτω μέρος */
  }

  .menu-social-icon {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 480px) {
  .menu-link {
    font-size: 1.2rem;
  }

  .menu-social-icon {
    width: 25px;
    height: 25px;
  }
}
