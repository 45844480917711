.about-container {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  background-color: black; /* Για να διασφαλίσουμε ότι δεν υπάρχουν λευκές περιοχές */
}

.about-logo {
  position: absolute;
  top: 5%;
  left: 2%;
  width: 12vw;
  z-index: 10; /* Υψηλό z-index για το λογότυπο ώστε να είναι πάντα πάνω από το φόντο */
}

.about-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Χαμηλό z-index για το βίντεο */
  background-color: rgba(0, 0, 0, 0.5);
}

.about-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Χαμηλό z-index για να είναι πίσω από το περιεχόμενο */
}

/* Εφαρμογή ξεχωριστού margin και padding για κάθε τίτλο και παράγραφο */

/* The Idea Generator τίτλος */
#idea-generator-title {
  font-family: 'PFHighwayGothicBlack', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-top: 120px; /* Προσαρμόζουμε το περιθώριο πάνω */
  margin-bottom: 10px; /* Προσαρμόζουμε το περιθώριο κάτω */
  padding-left: 80px; /* Αυξάνουμε την απόσταση από τα αριστερά για να έρθει πιο δεξιά */
  z-index: 2;
  position: relative;
}

/* Born Innovative τίτλος */
#born-innovative-title {
  font-family: 'PFHighwayGothicBlack', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-top: 100px; /* Προσαρμόζουμε το περιθώριο πάνω */
  margin-bottom: 10px; /* Προσαρμόζουμε το περιθώριο κάτω */
  padding-left: 80px; /* Ευθυγράμμιση προς τα δεξιά */
  z-index: 2;
  position: relative;
}

/* The Mindgen Companies τίτλος */
.about-companies-title {
  font-family: 'PFHighwayGothicBlack', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-top: 100px;
  padding-left: 80px; /* Ευθυγράμμιση προς τα δεξιά */
  z-index: 2;
  position: relative;
}

/* Εφαρμογή ξεχωριστού margin και padding για τις παραγράφους */

/* Παράγραφοι The Idea Generator */
#idea-generator-group .about-paragraph {
  font-family: 'PFHighwayGothicLight', sans-serif;
  font-size: 1.2rem;
  color: white;
  max-width: 800px;
  margin-bottom: 20px; /* Προσαρμόζουμε τα κενά ανάμεσα στις παραγράφους */
  padding-left: 80px; /* Ευθυγράμμιση προς τα δεξιά */
  z-index: 2;
  position: relative;
}

/* Παράγραφοι Born Innovative */
#born-innovative-group .about-paragraph {
  font-family: 'PFHighwayGothicLight', sans-serif;
  font-size: 1.2rem;
  color: white;
  max-width: 800px;
  margin-bottom: 20px; /* Προσαρμόζουμε τα κενά ανάμεσα στις παραγράφους */
  padding-left: 80px; /* Ευθυγράμμιση προς τα δεξιά */
  z-index: 2;
  position: relative;
}

.about-logos {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding-left: 80px; /* Ευθυγράμμιση προς τα δεξιά */
  z-index: 2;
  position: relative;
}

.about-logo-item {
  width: 90px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 2;
  position: relative;
}

.about-logo-item:hover {
  transform: scale(1.1);
}

.about-content {
  position: relative;
  z-index: 2;
  padding-top: 30px; /* Προσαρμογή για καλύτερη τοποθέτηση */
}

/* Slide-in animations */
@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.title-slide-left {
  opacity: 0;
  transform: translateX(-100%);
  animation: slide-in-left 1.5s ease forwards;
}

.paragraph-slide-left {
  opacity: 0;
  transform: translateX(-100%);
  animation: slide-in-left 1.5s ease forwards;
}

.logo-slide-left {
  opacity: 0;
  transform: translateX(-100%);
  animation: slide-in-left 1.5s ease forwards;
}

/* Εμφανίσεις με καθυστέρηση */
#idea-generator-title {
  animation-delay: 1s;
}

#idea-generator-group .about-paragraph {
  animation-delay: 2s;
}

#born-innovative-title {
  animation-delay: 4s;
}

#born-innovative-group .about-paragraph {
  animation-delay: 5s;
}

.about-companies-title {
  animation-delay: 6.5s;
}

.about-logos {
  animation-delay: 7s;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  #idea-generator-title,
  #born-innovative-title,
  .about-companies-title {
    font-size: 2rem;
  }

  #idea-generator-group .about-paragraph,
  #born-innovative-group .about-paragraph {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  #idea-generator-title,
  #born-innovative-title {
    font-size: 1.8rem;
  }

  #idea-generator-group .about-paragraph,
  #born-innovative-group .about-paragraph {
    font-size: 1rem;
  }

  .about-companies-title {
    font-size: 1.6rem;
  }

  .about-logo-item {
    width: 80px;
  }

  .about-logo {
    width: 25vw;
  }

  .about-content {
    padding: 30px 5%;
    padding-top: 100px;
    padding-left: 30px;
  }
}

@media (max-width: 480px) {
  #idea-generator-title,
  #born-innovative-title {
    font-size: 1.6rem;
  }

  #idea-generator-group .about-paragraph,
  #born-innovative-group .about-paragraph {
    font-size: 0.9rem;
  }

  .about-companies-title {
    font-size: 1.4rem;
  }

  .about-logo-item {
    width: 60px;
  }
}
