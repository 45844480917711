/* Contact.css */

/* Ορισμός γραμματοσειρών */
@font-face {
    font-family: 'PFHighwayGothicLight';
    src: url('../../../public/assets/PFHighwayGothicExtnd-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PFHighwayGothicBlack';
    src: url('../../../public/assets/PFHighwayGothicExtnd-Black.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.contact-container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ώστε να ξεκινάει από πιο πάνω */
    align-items: center;
    background-color: black;
    position: relative;
    overflow-y: auto;
    padding-top: 190px; /* Προσθέτουμε κενό πάνω από τα στοιχεία */
}

.contact-logo-link {
    position: absolute;
    top: 5%;
    left: 2%;
}

.contact-logo {
    width: 15vw;
    height: auto;
}

/* Μεγαλώνουμε το λογότυπο σε mobile */
@media (max-width: 768px) {
    .contact-logo {
        width: 30vw; /* Αυξάνουμε το μέγεθος του λογότυπου σε mobile */
    }
}

.contact-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    max-width: 1200px;
    margin-top: 4rem; /* Μεγαλύτερο κενό πάνω από το περιεχόμενο */
    gap: 100px;
    color: white;
}

.contact-info {
    font-family: 'PFHighwayGothicLight', sans-serif;
    width: 45%;
    font-size: 1.5rem;
    text-align: right;
    margin-bottom: 40px; /* Επιπλέον κενό κάτω από τη διεύθυνση */
}

.contact-info p {
    margin-bottom: 20px;
}

.contact-form {
    width: 45%;
    color: white;
}

.contact-form h2 {
    font-family: 'PFHighwayGothicBlack', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 30px;
    margin-top: -20px;
}

.contact-form input,
.contact-form textarea {
    font-family: 'PFHighwayGothicLight', sans-serif; /* Γραμματοσειρά */
    font-size: 1.4rem; /* Μεγαλύτερο μέγεθος γραμματοσειράς */
    width: 100%;
    padding: 10px;
    margin-bottom: 20px; /* Αυξημένο κενό ανάμεσα στα πεδία */
    border: none; /* Αφαιρούμε όλα τα περιγράμματα */
    border-bottom: 1px solid white; /* Προσθέτουμε μόνο λευκή γραμμή στο κάτω μέρος */
    background: none;
    color: white;
    outline: none; /* Αφαιρούμε το προεπιλεγμένο περίγραμμα όταν το πεδίο είναι ενεργό */
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-bottom: 2px solid white; /* Ενίσχυση της γραμμής κατά την εστίαση */
}

.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px; /* Αυξημένο κενό κάτω από το textarea */
    border: none;
    border-bottom: 1px solid white;
    background: none;
    color: white;
    outline: none;
    height: 120px; /* Αυξάνουμε το ύψος του textarea */
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
    font-family: 'PFHighwayGothicLight', sans-serif;
    font-size: 1.2rem; /* Αυξάνουμε το μέγεθος των placeholder */
    color: white;
}

/* Νέο στυλ για το div γύρω από το κουμπί */
.contact-form button {
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    cursor: pointer;
    margin-left: auto; /* Ευθυγράμμιση του κουμπιού στα δεξιά */
    display: block; /* Το κουμπί να πάρει όλο το μήκος διαθέσιμο */
    width: 150px;
}

.contact-form button:hover {
    background-color: white;
    color: black;
}

/* Εφαρμόζουμε ασπρόμαυρο φίλτρο στον χάρτη */
iframe {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    filter: grayscale(100%); /* Κάνει τον χάρτη ασπρόμαυρο */
}

/* Responsive προσαρμογές για mobile */
@media (max-width: 768px) {
    .contact-info {
        font-size: 1.2rem; /* Μικρότερη γραμματοσειρά για διεύθυνση */
        margin-bottom: 30px;
    }

    .contact-form h2 {
        font-size: 2rem; /* Μικρότερος τίτλος φόρμας */
    }

    .contact-form input,
    .contact-form textarea {
        margin-bottom: 20px;
    }

    .contact-content {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin-top: 4rem;
        margin-bottom: 100px;
    }

    .contact-info,
    .contact-form {
        width: 100%;
        max-width: 500px;
    }

    iframe {
        height: 200px; /* Μειώνουμε το ύψος του iframe σε mobile */
    }

    .contact-form button {
        width: 100%; /* Κεντράρισμα του κουμπιού στις μικρές οθόνες */
    }
}

footer {
    position: absolute;
    bottom: 10px; /* Τοποθέτηση στο κάτω μέρος */
    right: 20px;
    color: white;
    font-size: 1rem;
    text-align: right;
    width: auto; /* Εξασφαλίζουμε ότι το πλάτος είναι προσαρμοσμένο στο περιεχόμενο */
}
