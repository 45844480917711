/* Ολόκληρο το container της σελίδας Home */
.home-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: black;
}

/* Βίντεο εισαγωγής (landing video) */
.home-landing-video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: opacity 3s ease-in-out;
}

.home-landing-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Το intro video κάνει fade-out */
.fade-out-intro {
  opacity: 0;
  pointer-events: none;
  transition: opacity 3s ease-in-out;
}

/* Κύριο περιεχόμενο */
.home-main-content-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.home-main-content-container.fade-in {
  opacity: 1;
}

/* Λογότυπο της εταιρείας */
.home-logo {
  position: absolute;
  top: 5%;
  left: 2%;
  width: 15vw;
  height: auto;
  z-index: 10;
}

/* Προσαρμογή λογότυπου για mobile */
@media (max-width: 768px) {
  .home-logo {
    width: 30vw; /* Αύξηση μεγέθους λογότυπου για mobile */
  }
}

.home-logo-link {
  position: absolute;
  top: 6%;
  left: 4%;
  z-index: 10;
}

/* Το κύριο βίντεο */
.home-main-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

/* Βίντεο που εμφανίζονται στο hover */
.hover-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
}

/* Τοποθέτηση των SVG κουμπιών γύρω από το M */
.svg-button {
  position: absolute;
  width: 10vw;
  height: auto;
  cursor: pointer;
  z-index: 10;
}

/* Προσαρμογές για το μέγεθος και τη θέση των κουμπιών */
.mindwise {
  top: 45%;
  left: 20%;
}

.mindtrap {
  top: 20%;
  right: 25%;
}

.mindsets {
  top: 60%;
  right: 22%;
}

/* Προσαρμογή για τα κουμπιά σε mobile */
@media (max-width: 768px) {
  .svg-button {
    width: 17vw; /* Αύξηση μεγέθους των εικονιδίων */
  }

  .mindwise {
    top: 45%;
    left: 5%;
  }

  .mindtrap {
    top: 30%;
    right: 20%;
  }

  .mindsets {
    top: 45%;
    right: 5%;
  }
}

/* Προσαρμογές για tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .home-logo {
    width: 25vw; /* Μεγαλύτερο λογότυπο για tablet */
  }

  .svg-button {
    width: 14vw; /* Αύξηση μεγέθους των εικονιδίων για tablet */
  }

  .mindwise {
    top: 45%;
    left: 10%; /* Μετακίνηση πιο αριστερά */
  }

  .mindtrap {
    top: 30%; /* Μετακίνηση πιο κάτω */
    right: 15%;
  }

  .mindsets {
    top: 50%; /* Μετακίνηση πιο πάνω */
    right: 10%; /* Μετακίνηση πιο δεξιά */
  }
}

/* Burger Menu */
.menu-icon-container {
  position: absolute;
  top: 4%;
  right: 4%;
  z-index: 10;
}

.menu-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Slide-in Menu */
.menu-container {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%; /* Το μενού θα καλύπτει ολόκληρο το πλάτος της οθόνης */
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  transition: right 1s ease-in-out;
  z-index: 1001;
}

.menu-open {
  right: 0;
}

/* Προσαρμογή θέσης για το "X" */
.menu-close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Στυλ για το container που περιέχει τα links */
.menu-links-container {
  position: relative;
  top: 30%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* Στυλ για τα links "About Us" και "Contact" */
.menu-link {
  font-size: 2rem;
  color: black;
  text-decoration: none;
  opacity: 0;
  transform: translateY(100vh);
  transition: opacity 0.8s ease, transform 1.5s ease;
  z-index: 10;
  text-align: left;
}

/* Slide-in για τα links */
.menu-open .slide-in-item {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.5s;
}

/* Hover effect με γραμμή υπογράμμισης */
.menu-link::before {
  content: '';
  position: absolute;
  width: 90%;
  height: 2px;
  bottom: 0;
  left: -120%;
  background-color: black;
  transition: left 0.3s ease-in-out;
}

.menu-link:hover::before {
  left: 0;
}

/* Social media container in the center of the menu */
.menu-socials-container {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Κοινό στυλ για τα social media icons */
.menu-social-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Προσαρμογές για mobile */
@media (max-width: 768px) {
  .menu-link {
    font-size: 1.5rem;
  }

  .menu-container {
    width: 100vw;
    height: 100vh;
  }

  .menu-social-icon {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 480px) {
  .menu-link {
    font-size: 1.2rem;
  }

  .menu-social-icon {
    width: 25px;
    height: 25px;
  }
}
